/* Import the font family in your global CSS or use a font service like Google Fonts */

@import url('https://fonts.googleapis.com/css2?family=ITC+Officina+Display:wght@400;700&display=swap');
.container {
    font-family: 'Axel, ITC Officina Display', sans-serif;
    font-size: 18px;
    color: #333;
    /* Default text color */
    max-width: 900px;
    /* Updated max-width */
    margin: 40px auto;
    /* Space before and after the container */
    padding: 40px;
    background: linear-gradient(135deg, #ffffff, #f9f5e2);
    /* Muted yellow background gradient */
    border-radius: 15px;
    /* More pronounced border-radius */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    /* Main shadow for the container */
    position: relative;
    /* Position relative for pseudo-element positioning */
}


/* Removed the bottom shadow effect */

.container::after {
    content: "";
    display: none;
    /* Remove the pseudo-element */
}

.title {
    color: #9ccc52;
    /* Green color for the title */
    font-size: 25px;
    /* Larger font size for prominence */
    font-weight: 700;
    /* Bold text for emphasis */
    margin-bottom: 30px;
    /* More space between title and list */
    text-align: center;
    /* Center-align the title */
    position: relative;
    /* For pseudo-element positioning */
    letter-spacing: 1px;
    /* Slightly increased letter-spacing */
    text-transform: uppercase;
    /* Uppercase title for emphasis */
}

.title::after {
    content: "";
    display: block;
    width: 80px;
    /* Wider underline for a more impactful look */
    height: 6px;
    /* Consistent thickness */
    background: #f5b830;
    /* Muted yellow underline */
    position: absolute;
    left: 50%;
    bottom: -12px;
    /* Position it slightly below the title */
    transform: translateX(-50%);
    /* Center the underline */
    border-radius: 3px;
    /* Rounded corners for the underline */
}

.list {
    list-style-type: none;
    /* Remove default bullets */
    padding-left: 0;
    margin: 20px 0;
    /* Add space before and after the list */
}

.list li {
    margin-bottom: 20px;
    /* Increased spacing between items */
    color: #333;
    /* Default text color */
    line-height: 1.6;
    /* Adjusted line height for readability */
    padding: 15px;
    border-radius: 8px;
    /* Rounded corners for list items */
    background: #ffffff;
    /* Clean background for list items */
    border: 1px solid #e0e0e0;
    /* Subtle border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    /* Subtle shadow for list items */
    position: relative;
    /* For pseudo-element positioning */
    padding-left: 40px;
    /* Space for custom bullet */
}

.list li::before {
    content: "➤";
    /* Arrow bullet point */
    position: absolute;
    left: 10px;
    /* Position the arrow inside the padding */
    color: #f5b830;
    /* Muted yellow color for the arrow */
    font-size: 20px;
    /* Size of the arrow */
    top: 50%;
    transform: translateY(-50%);
    /* Center the arrow vertically */
}