@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

/* CSS Reset */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p {
    margin-bottom: 1em;
    /* Ensures paragraphs have space below them */
}


/* Global styles */

body {
    height: 100%;
    font-family: 'Axel, ITC Officina Display', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
}

footer {
    background-color: '#9ccd4f';
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}