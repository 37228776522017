/* src/pages/FAQ.css */

@import url('https://fonts.googleapis.com/css2?family=ITC+Officina+Display:wght@400;700&display=swap');
.faq-container {
    font-family: 'Axel, ITC Officina+Display', sans-serif;
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background: linear-gradient(135deg, #ffffff, #f9f5e2);
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.faq-item {
    margin-bottom: 20px;
    /* Increased space for better separation */
}

.faq-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9ccd4f;
    /* Green background */
    color: #333;
    /* Darker text color for better contrast */
    padding: 15px;
    /* Increased padding for a more comfortable click area */
    border-radius: 8px;
    /* Slightly more pronounced border radius */
    transition: background-color 0.3s, transform 0.3s;
    /* Smooth transitions */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Light shadow for depth */
}

.faq-question:hover {
    background-color: #88b043;
    /* Darker green on hover */
    transform: scale(1.02);
    /* Slightly scale up on hover for effect */
}

.arrow {
    font-size: 20px;
    /* Slightly larger arrow */
    transition: transform 0.3s ease;
    color: #f2d24e;
    /* Arrow color */
}

.arrow.expanded {
    transform: rotate(90deg);
    /* Rotate arrow when expanded */
}

.faq-answer {
    margin-top: 10px;
    padding: 15px;
    /* More padding for comfort */
    background-color: #ffffff;
    /* Clean background for answers */
    border-radius: 8px;
    /* Consistent border radius */
    border: 1px solid #e0e0e0;
    /* Subtle border */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    /* Shadow for better visibility */
    line-height: 1.6;
    /* Improved readability */
}